import React from "react";
import { Helmet } from "react-helmet"

import Layout from "../components/Layout";
import OfferingContent from "../components/OfferingContent";


const Offering = ({pageContext}) => {

    return (
        <Layout 
            pageContext={pageContext}
            extraClass={"offering-container"}
            isEmbedded={true}
        >
            <Helmet>
                <script type="text/javascript">
                    {`
                    function sendHeight()
                    {
                        if(parent.postMessage)
                        {
                            // replace #wrapper with element that contains 
                            // actual page content
                            var height= document.getElementsByClassName('offering-container')[0].offsetHeight;
                            parent.postMessage(height, '*');
                        }
                    }
                    setTimeout(sendHeight, 100);
                    setTimeout(sendHeight, 500);
                    setTimeout(sendHeight, 1000);
                    `}
                </script>
            </Helmet>
            {/* <Helmet>
                <script type="text/javascript">{`
                    console.log("started something");
                    function sendHeight()
                    {
                        console.log("sending height");
                        if(parent.postMessage)
                        {
                            // replace #wrapper with element that contains 
                            // actual page content
                            var height= document.getElementById('wrapper').offsetHeight;
                            parent.postMessage(height, '*');
                        }
                    }

                    // Create browser compatible event handler.
                    var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
                    var eventer = window[eventMethod];
                    var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

                    // Listen for a message from the iframe.
                    eventer(messageEvent, function(e) {

                        if (isNaN(e.data)) return;

                        sendHeight();

                    }, 
                    false);
                `}</script>
            </Helmet> */}
            <OfferingContent pageContext={pageContext} isEmbedded={true} />
        </Layout>
    )
};

export default Offering;